import React, { useContext, useState } from "react";
import {
    Stepper,
    Step,
    StepLabel,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Box,
    Container,
    Tooltip,
    IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Typography from "@mui/joy/Typography";
import CheckoutForm from "../../components/CheckoutForm.tsx";
import { FormDataContext } from '../../FormDataContext';
import apiClient from '../../apiClient';
import { boxStyle } from "../../utils/constants.js";

const getSteps = () => {
    return ['Fill in Child Info', 'Add Picture', 'Choose Product', 'Review', 'Pay'];
};

const columnBoxStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: 'auto',
    paddingTop: 10,
    paddingBottom: 5,
};

const TooltipField = ({ label, tooltip, value, onChange, error, ...props }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
                label={label}
                value={value || ''}
                onChange={onChange}
                error={error}
                helperText={error ? `Please enter ${label.toLowerCase()}.` : ''}
                {...props}
            />
            <Tooltip title={tooltip}>
                <IconButton aria-label={`${label}-info`}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
        </Box>
    </Box>
);

const StepContent = ({ step, handleNext, handlePrev, handleSelectChange, handleChange, handleFileChange, selectedImage, formData, setFormData, errors }) => {
    switch (step) {
        case 0:
            return (
                <Box sx={boxStyle}>
                    <TooltipField
                        label="First Name"
                        tooltip="Enter the child's first name. This would be the name of the child in the book."
                        value={formData.child_first_name}
                        onChange={(e) => handleChange('child_first_name', e.target.value)}
                        error={errors.child_first_name}
                    />
                    <TooltipField
                        label="Age"
                        tooltip="Enter the child's age. This would be the age of the child in the book."
                        value={formData.age}
                        onChange={(e) => handleChange('age', e.target.value)}
                        error={errors.age}
                        type="number"
                    />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={errors.race}>
                        <InputLabel id="race-select-label">Race</InputLabel>
                        <Select
                            labelId="race-select-label"
                            id="race-select"
                            value={formData.race}
                            onChange={(e) => handleSelectChange('race', e.target.value)}
                            label="Race"
                        >
                            <MenuItem value={'American Indian'}>American Indian</MenuItem>
                            <MenuItem value={'Asian'}>Asian</MenuItem>
                            <MenuItem value={'Black'}>Black</MenuItem>
                            <MenuItem value={'Hispanic'}>Hispanic</MenuItem>
                            <MenuItem value={'White'}>White</MenuItem>
                        </Select>
                        {errors.race && <Typography color="error" variant="caption">Please select a race.</Typography>}
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={errors.gender}>
                        <InputLabel id="gender-select-label">Gender</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            id="gender-select"
                            value={formData.gender}
                            onChange={(e) => handleSelectChange('gender', e.target.value)}
                            label="Gender"
                        >
                            <MenuItem value={'male'}>Male</MenuItem>
                            <MenuItem value={'female'}>Female</MenuItem>
                        </Select>
                        {errors.gender && <Typography color="error" variant="caption">Please select a gender.</Typography>}
                    </FormControl>
                    <TooltipField
                        label="Title (Optional)"
                        tooltip="Enter the book's title."
                        value={formData.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                    />
                    <TooltipField
                        label="Prompt"
                        tooltip="Write a short prompt for the book (e.g., Write me a book about a boy named Gary who cures cancer)."
                        value={formData.prompt}
                        multiline
                        onChange={(e) => handleChange('prompt', e.target.value)}
                        sx={{ minWidth: '40%' }}
                        placeholder='Write me a book about a boy named Gary who cures cancer.'
                        error={errors.prompt}
                    />
                    <Box sx={columnBoxStyle}>
                        <Button variant="contained" onClick={handleNext} sx={{ margin: 1 }}>Next</Button>
                    </Box>
                </Box>
            );
        case 1:
            return (
                <Box sx={boxStyle}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h4" sx={{ mt: 4, mb: 4 }}>
                            Upload A Photo (Optional)
                        </Typography>
                        <Tooltip title="This photo will be used to create a character for the book. If this is not provided, a character will be created using the information provided. Please ensure it is a clear selfie of only the child.">
                            <IconButton aria-label="upload-info">
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > input': {
                                display: 'none',
                            },
                        }}
                    >
                        <input
                            accept="image/*"
                            type="file"
                            id="contained-button-file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span">
                                Upload Photo
                            </Button>
                        </label>
                        {selectedImage && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Preview:
                                </Typography>
                                <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                            </Box>
                        )}
                        <Box sx={columnBoxStyle}>
                            <Button variant="contained" onClick={handlePrev} sx={{ margin: 1 }}>Back</Button>
                            <Button variant="contained" onClick={handleNext} sx={{ margin: 1 }}>Next</Button>
                        </Box>
                    </Box>
                </Box>
            );

        case 2:
            return (
                <Box sx={boxStyle}>
                    <Typography variant="h4" sx={{ mt: 4, mb: 4 }}>
                        Choose Your Product
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ textAlign: 'center', flex: 1, marginRight: { sm: 2, xs: 0 }, marginBottom: { xs: 2, sm: 0 } }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setFormData(prevFormData => ({ ...prevFormData, product_type: 'Digital' }));
                                    handleNext();
                                }}
                                sx={{ width: '100%' }}
                            >
                                Digital - $29.99
                            </Button>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Includes PDF version of the book, accessible anytime online.
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', flex: 1, marginLeft: { sm: 2, xs: 0 } }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setFormData(prevFormData => ({ ...prevFormData, product_type: 'Physical' }));
                                    handleNext();
                                }}
                                sx={{ width: '100%' }}
                            >
                                Physical - $39.99
                            </Button>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Includes PDF version of the book and a physical saddle stitched copy of the book.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={columnBoxStyle}>
                        <Button variant="contained" onClick={handlePrev} sx={{ margin: 1 }}>Back</Button>
                    </Box>
                </Box>
            );
        case 3:
            return (
                <Box sx={boxStyle}>
                    <Typography variant="h4" sx={{ mt: 4, mb: 4 }}>
                        Review Your Book Information
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="First Name"
                            value={formData.child_first_name}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="filled"
                        />
                        <TextField
                            label="Age"
                            value={formData.age}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="filled"
                        />
                        <TextField
                            label="Race"
                            value={formData.race}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="filled"
                        />
                        <TextField
                            label="Gender"
                            value={formData.gender}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="filled"
                        />
                        {formData.title && (
                            <TextField
                                label="Title"
                                value={formData.title}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="filled"
                            />
                        )}
                        <TextField
                            label="Prompt"
                            value={formData.prompt}
                            InputProps={{
                                readOnly: true,
                            }}
                            multiline
                            variant="filled"
                        />
                        <TextField
                            label="Product Type"
                            value={formData.product_type}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="filled"
                        />
                        {selectedImage && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Uploaded Photo:
                                </Typography>
                                <img src={selectedImage} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                            </Box>
                        )}
                    </Box>
                    <Box sx={columnBoxStyle}>
                        <Button variant="contained" onClick={handlePrev} sx={{ margin: 1 }}>Back</Button>
                        <Button variant="contained" onClick={handleNext} sx={{ margin: 1 }}>Next</Button>
                    </Box>
                </Box>
            );
        case 4:
            return (
                <Box sx={boxStyle}>
                    <CheckoutForm formData={formData} />
                    <Button variant="contained" onClick={handlePrev} sx={{ margin: 1 }}>Back</Button>
                </Box>
            );
        default:
            return null;
    }
};

const ChildBookPage = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useContext(FormDataContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const steps = getSteps();

    const handleNext = () => {
        if (validateStep(activeStep)) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSelectChange = (field, value) => {
        setFormData(prevFormData => ({ ...prevFormData, [field]: value }));
    };

    const handleChange = (field, value) => {
        setFormData(prevFormData => ({ ...prevFormData, [field]: value }));
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));

            const data = new FormData();
            data.append("file", file);

            try {
                const response = await apiClient.post('/upload', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response && response.data) {
                    const img_id = response.data.image_id || response.data.imageId;

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        img_id: img_id,
                    }));
                }

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const validateStep = (step) => {
        let isValid = true;
        let newErrors = {};

        if (step === 0) {
            if (!formData.child_first_name) {
                isValid = false;
                newErrors.child_first_name = true;
            }
            if (!formData.age || isNaN(formData.age)) { // || formData.age < 4 || formData.age > 10)
                isValid = false;
                newErrors.age = true;
            }
            if (!formData.gender) {
                isValid = false;
                newErrors.gender = true;
            }
            if (!formData.race) {
                isValid = false;
                newErrors.race = true;
            }
            if (!formData.prompt) {
                isValid = false;
                newErrors.prompt = true;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    return (
        <Container>
            <Box sx={{ width: '100%', mt: 5 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <StepContent
                    step={activeStep}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    handleSelectChange={handleSelectChange}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    selectedImage={selectedImage}
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                />
            </Box>
        </Container>
    );
};

export default ChildBookPage;
