import HeroMain from '../../components/HeroMain.tsx';
import TextImageLayout from '../../components/TextImageLayout.tsx';

function HomePage() {
  return (
    <div className="HomePage">
      <HeroMain image='/images/writeyourideas.png' />
      <TextImageLayout
        title='Create the stories you want'
        description='Make A Book will create completely unique stories for with some simple input from you.'
        reversed={true}
        image='/images/creativity.png'
      />
      <TextImageLayout
        title='Curated and engaging narratives'
        description='Make A Book, will develop compelling characters and create a captivating story.
       Our platform takes care of the storywriting process, ensuring your narratives are engaging and well-structured.'
        image='/images/familyreadingbook.png'
      />
      <TextImageLayout
        title='The greatest character'
        description='Make A Book creates a character for your story using the image and information you provide. Get the most unique story possible. Created for you, by you.'
        reversed={true}
        image='/images/char_photo.png'
      />
    </div>
  );
}

export default HomePage;
