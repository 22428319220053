import React, { useCallback } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import apiClient from "../apiClient";
import { isProduction } from "../utils/envUtils";

const spk = isProduction() ? "pk_live_51ODWEUC9IgQQTpICWO6y2Qhfl1BXC88IW9zL4FeRjqLqjrfD2eemP8i7Wj30YpbnytzecxMr1ag5QDZimfe6fhqY00N8tHUKMf" : "pk_test_51ODWEUC9IgQQTpICDSteYPol9PuqYCDHRu8pi85dm6ChnJZji821G3JywZl4CwhXSPCWDwVDyUSBtO7rcOEjULMo001Z4woYzX";
const stripePromise = loadStripe(spk);

const CheckoutForm = ({ formData }) => {
    const fetchClientSecret = useCallback(() => {
        return apiClient.post('/create-checkout-session', { "product_type": formData.product_type })
            .then((res) => res.data.clientSecret);
    }, [formData]);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};

export default CheckoutForm;